import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MaterialService } from 'services/material-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NettingColorService } from 'services/netting-color-service';
import { NettingService } from 'services/netting-service';
import { NettingTypeService } from 'services/netting-type-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { PlacementService } from 'services/placement-service';
import { ThreadTypeService } from 'services/thread-type-service';
import { ToastService } from 'services/toast-service';
import { validationRules } from './helper';

@containerless
@autoinject
export class NettingFormInlineEdit {
  @bindable
  protected isMorenotNet = true;

  @bindable
  private netting: Models.Netting;
  @bindable
  private dimensionClassId: number;

  @bindable
  private netId: number;

  @bindable
  private netShapeId: number;
  @bindable
  private isCombiNet = false;
  async isCombiNetChanged() {
    this.cancelEdit();
  }
  @bindable
  private netHasSameNetting = true;
  async netHasSameNettingChanged() {
    await this.getPlacements();
  }

  protected nettingStrengthLowerThenRequirements = false;
  protected nettingMissingActualBreakingStrength = false;

  private currentNettingType: Models.NettingType;
  private validCombinations: { [shapeId: number]: Array<number> };

  public placements: Models.Placement[] = [];
  protected validationController: ValidationController;

  constructor(
    private nettingService: NettingService,
    private nettingTypeService: NettingTypeService,
    private meshSizeService: MeshSizeService,
    private placementService: PlacementService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService,
    protected nettingColorService: NettingColorService,
    protected materialService: MaterialService,
    protected threadTypeService: ThreadTypeService,
    protected nylonTypeService: NylonTypeService,
    protected validationControllerFactory: ValidationControllerFactory,
    protected validator: Validator,
    private t: I18N
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  private applyValidation(isMorenotNet: boolean) {
    validationRules(this.t, isMorenotNet)?.on(this.netting);
  }

  protected async isFormValid() {
    const result = await this.validationController.validate({
      object: this.netting,
    });

    return result.valid;
  }

  async attached() {
    try {
      this.validCombinations = this.placementService.getValidCombinations(this.isCombiNet);
      await this.getPlacements();
      this.applyValidation(this.isMorenotNet);

      if (this.netting.NettingTypeId) {
        const currentNettingType = await this.nettingTypeService.get(this.netting.NettingTypeId);
        this.currentNettingType = currentNettingType;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  trySetRequirementsWarning(strengthRequirement: number) {
    if (
      this.netting.ActualBreakingStrength &&
      strengthRequirement &&
      this.netting.ActualBreakingStrength < strengthRequirement
    ) {
      this.nettingStrengthLowerThenRequirements = true;
    } else {
      this.nettingStrengthLowerThenRequirements = false;
    }

    if (!this.netting.ActualBreakingStrength) {
      this.nettingMissingActualBreakingStrength = true;
    } else {
      this.nettingMissingActualBreakingStrength = false;
    }
  }

  bind() {
    if (this.netting.MeshSize) {
    this.nettingService
      .getNettingStrengthRuleByNetId(this.netId, this.netting.MeshSize.MeshSizeMm)
      .then((result) => {
        if (this.dimensionClassId && this.netting.MeshSize && !this.netting.MinBreakingStrength) {
          this.netting.MinBreakingStrength = result.StrengthRequirement;
        }
        this.trySetRequirementsWarning(result.StrengthRequirement);
      })
      .catch(() => {
        // not much to do about this, invalid combination is used
      });

    }
  }

  private async getPlacements(): Promise<void> {
    try {
      this.placements = [];
      const allPlacements = await this.placementService.getAllCached();
      if (this.netHasSameNetting && this.netShapeId !== 7) {
        allPlacements.forEach((x) => {
          // only show AllNet if the net is set to have same netting on all net
          x.IsDeleted = x.Id !== 4;
        });
      } else {
        const validCombinations = this.validCombinations[this.netShapeId];
        allPlacements.forEach((x) => {
          // show values based on valid combination of placements and the nets shape
          x.IsDeleted = validCombinations.indexOf(x.Id) === -1;
        });
      }
      this.placements = allPlacements.filter((x) => !x.IsDeleted);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async updateNetting() {
    try {
      this.netting.Material = null;
      this.netting.MeshSize = null;
      this.netting.ThreadType = null;
      this.netting.NylonType = null;
      this.netting.NettingType = null;
      this.netting.NettingColor = null;
      this.netting.Placement = null;

      await this.nettingService.put(this.netting, this.netting.Id);
      this.eventAggregator.publish('nettingListReset', 1);
      this.eventAggregator.publish('nettingFormEditClose', 1);
      this.toastService.showSuccess('netting.updated');
      this.netting = new Models.Netting();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async getNettingTypes() {
    const nettingTypes = await this.nettingTypeService.getAllCached();
    return nettingTypes.filter((x) => x.NavisionProductId || x.DeltaProductId);
  }

  private changeMeshSize(event) {
    if (!event.detail.value || event.detail.value === this.netting.MeshSizeId) {
      return;
    }
    this.netting.MeshSizeId = event.detail.value;

    if (this.dimensionClassId && this.netting.MeshSizeId) {
      this.meshSizeService.getAllCached().then((meshSizes) => {
        const meshSize = meshSizes.find((x) => x.Id == this.netting.MeshSizeId);
        if (meshSize) {
          this.nettingService
            .getNettingStrengthRule(this.dimensionClassId, meshSize.MeshSizeMm)
            .then((result) => {
              this.netting.MinBreakingStrength = result.StrengthRequirement;
            })
            .catch((err) => {
              this.toastService.showError('netting.couldNotCalculateMinBreakingStrengthSpecifyManually');
            });
        }
      });
    }
  }

  private changeNettingType(event) {
    if (!event.detail.value || event.detail.value === this.netting.NettingTypeId) {
      return;
    }
    this.netting.NettingTypeId = event.detail.value;

    this.nettingTypeService
      .get(this.netting.NettingTypeId + '?$expand=MeshSize,NavisionProduct')
      .then((res) => {
        this.currentNettingType = res;
        this.netting.MeshSizeId = this.currentNettingType.MeshSizeId;
        this.netting.NylonTypeId = this.currentNettingType.NylonTypeId;
        this.netting.MaterialId = this.currentNettingType.MaterialId;
        this.netting.ThreadTypeId = this.currentNettingType.ThreadTypeId;
        this.netting.Omfar = this.currentNettingType.Omfar;
        this.netting.Solidity = this.currentNettingType.Solidity;
        this.netting.MeshOpening = this.currentNettingType.MeshOpening;

        if (this.currentNettingType.NavisionProduct) {
          this.netting.ActualBreakingStrength = this.currentNettingType.NavisionProduct.MinBreakingStrength;
        }

        if (this.dimensionClassId) {
          if (this.currentNettingType.MeshSize) {
            this.nettingService
              .getNettingStrengthRuleByNetId(this.netId, this.currentNettingType.MeshSize.MeshSizeMm)
              .then((result) => {
                this.netting.MinBreakingStrength = result.StrengthRequirement;
                this.trySetRequirementsWarning(result.StrengthRequirement);
              })
              .catch((err) => {
                this.toastService.showError('netting.couldNotCalculateMinBreakingStrengthSpecifyManually');
              });
          }
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('nettingFormEditClose', 1);
    this.eventAggregator.publish('nettingListReset', 1);
  }
}
