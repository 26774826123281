import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { NettingColorService } from 'services/netting-color-service';

@autoinject
export class NettingColorDetail {
  private nettingcolor: Models.NettingColor;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private nettingColorService: NettingColorService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.nettingColorService
      .get(params.Id)
      .then( (res) => {
        this.nettingcolor = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateNettingColor() {
    this.nettingColorService
      .put(this.nettingcolor, this.nettingcolor.Id)
      .then((res) => {
        this.eventAggregator.publish('nettingColorListReset', 1);
        this.toastService.showSuccess('nettingcolor.updated');
        this.router.navigateToRoute('netting-color-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteNettingColor() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.nettingColorService
          .delete(this.nettingcolor.Id)
          .then((res) => {
            this.eventAggregator.publish('nettingColorListReset', 1);
            this.toastService.showSuccess('nettingcolor.deleted');
            this.router.navigateToRoute('netting-color-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );  
  }

  private gotoParentView() {
    this.eventAggregator.publish('nettingColorListReset', 1);
    this.router.navigateToRoute('netting-color-list');
  }
}
