import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { NettingColorService } from 'services/netting-color-service';

@autoinject
export class NettingColorNew {
  private nettingcolor: Models.NettingColor = new Models.NettingColor();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private nettingColorService: NettingColorService
  ) {}

  private createNettingColor() {
    this.nettingColorService
      .post(this.nettingcolor)
      .then((res) => {
        this.eventAggregator.publish('nettingColorListReset', 1);
        this.toastService.showSuccess('nettingcolor.created');
        this.router.navigateToRoute('netting-color-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
