import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { NylonTypeService } from "services/nylon-type-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class NylonTypeNew {
  private nylontype: Models.NylonType = new Models.NylonType();

  constructor(
    private nylonTypeService: NylonTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createNylonType() {
    this.nylonTypeService
      .post(this.nylontype)
      .then(res => {
        this.eventAggregator.publish("nylonTypeListReset", 1);
        this.toastService.showSuccess("nylontype.created");
        this.router.navigateToRoute("nylon-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
