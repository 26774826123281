import { DeleteDialogService } from 'services/delete-dialog-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NettingTypeService } from 'services/netting-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("netting-type-detail");

@autoinject
export class NettingTypeDetail {
  private nettingtype: Models.NettingType;
  private originalObject;

  constructor(
    private nettingTypeService: NettingTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private controller: DialogController,
    private dialogService: DialogService,
    private utility: Utility,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.nettingTypeService
      .get(params.Id)
      .then((nettingType) => {
        this.nettingtype = nettingType;
        this.originalObject = JSON.parse(JSON.stringify(nettingType));
      })
      .catch((err) => this.errorService.handleError(err));

    this.eventAggregator.publish('input-autocomplete-refresh', 1);

  }

  private canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.nettingtype, this.originalObject)) {
      logger.debug(this.originalObject, this.nettingtype);
      return this.dialogService.open({
        viewModel: Prompt,
        model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'save') {
            // save the nettingtype and let that function handle the rest of the logic
            this.updateNettingType();
            return false;
          } else {
            return true;
          }
        }
      });
    } else {
      return true;
    }
  }

  private updateNettingType() {
    this.nettingTypeService
      .put(this.nettingtype, this.nettingtype.Id)
      .then((res) => {
        this.originalObject = null;
        this.eventAggregator.publish('nettingTypeListReset', 1);
        this.toastService.showSuccess('nettingtype.updated');
        this.router.navigateToRoute('netting-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteNettingType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.nettingTypeService
          .delete(this.nettingtype.Id)
          .then((res) => {
            this.originalObject = null;
            this.eventAggregator.publish('nettingTypeListReset', 1);
            this.toastService.showSuccess('nettingtype.deleted');
            this.router.navigateToRoute('netting-type-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );  
  }

  private gotoParentView() {
    this.eventAggregator.publish('nettingTypeListReset', 1);
    this.router.navigateToRoute('netting-type-list');
  }
}
