import { ValidationRules } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';

const minStrength = 20;
const maxStrength = 500;

export const validationRules = (t: I18N, isMorenotNet: boolean) => {
  if (isMorenotNet) return;
  return ValidationRules.ensure('ActualBreakingStrength')
    .required()
    .withMessage(t.tr('validation.required'))
    .min(minStrength)
    .withMessage(t.tr('validation.mustBeGreaterOrEqualToValue', { value: minStrength }))
    .max(maxStrength)
    .withMessage(t.tr('validation.mustBeLessOrEqualToValue', { value: maxStrength }))
    .ensure('MinBreakingStrength')
    .required()
    .withMessage(t.tr('validation.required'))
    .min(minStrength)
    .withMessage(t.tr('validation.mustBeGreaterOrEqualToValue', { value: minStrength }))
    .max(maxStrength)
    .withMessage(t.tr('validation.mustBeLessOrEqualToValue', { value: maxStrength }));
};
