import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MaterialService } from 'services/material-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { NylonTypeService } from 'services/nylon-type-service';
import { ProductService } from 'services/product-service';
import { ThreadTypeService } from 'services/thread-type-service';

@autoinject
export class NettingTypeForm {
  @bindable() private nettingtype: Models.NettingType;
  private currentProduct: Models.Product;

  constructor(
    private materialService: MaterialService,
    private threadTypeService: ThreadTypeService,
    private meshSizeService: MeshSizeService,
    private nylonTypeService: NylonTypeService,
    private errorService: ErrorService,
    private productService: ProductService
  ) { }

  private setNavisionProductId(event) {
    this.nettingtype.NavisionProductId = event.detail.value;
    this.setProductName(event.detail.value);
  }

  private setDeltaProductId(event) {
    this.nettingtype.DeltaProductId = event.detail.value;
    this.setProductName(event.detail.value);
  }

  private getProduct(id): Promise<any> {
    return new Promise((resolve, reject) => { this.productService.get(id)
      .then((res) => {
        this.currentProduct = res;
        resolve(res);
      })
      .catch((err) => this.errorService.handleError(err));
    });
  }

  private setProductName(productId: string) {
    if (!this.nettingtype.Name || this.nettingtype.Name === '') {
      if (productId) {
        this.getProduct(productId).then((res) => {
          this.nettingtype.Name = res.Name;
        });
      }
    }
  }
}
