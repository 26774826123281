import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { NylonTypeService } from "services/nylon-type-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class nylonTypeDetail {
  private nylontype: Models.NylonType;

  constructor(
    private nylonTypeService: NylonTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.nylonTypeService
      .get(params.Id)
      .then(nylonType => {
        this.nylontype = nylonType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateNylonType() {
    this.nylonTypeService
      .put(this.nylontype, this.nylontype.Id)
      .then(res => {
        this.eventAggregator.publish("nylonTypeListReset", 1);
        this.toastService.showSuccess("nylontype.updated");
        this.router.navigateToRoute("nylon-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteNylonType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.nylonTypeService
          .delete(this.nylontype.Id)
          .then(res => {
            this.eventAggregator.publish("nylonTypeListReset", 1);
            this.toastService.showSuccess("nylontype.deleted");
            this.router.navigateToRoute("nylon-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      ); 
  }
  
  gotoParentView(){
    this.eventAggregator.publish("nylonTypeListReset", 1);
    this.router.navigateToRoute("nylon-type-list");   
  }
}
